import s from './styles.module.css'
import { useRouter } from 'next/router'
import FsaBadge from 'components/payment/common/fsa-badge'
import BulletPoints from 'components/payment/common/bullet-points'

const LandingPagePlanCard = ({
  products,
  options = { overrideDailyPrice: undefined },
  landingPageOptions = { overrideCTA: undefined },
  coupon,
  billingPeriodInMonths,
  dailyPrice,
  dailyPriceWithoutCoupon,
  pricingDescription,
  onSelection = () => {},
  isLandingPage = false,
  isCoachPage = false,
  loading = false,
  isFreeTrial = false,
  firstMonthPriceUnitAmount,
}) => {
  const router = useRouter()
  const nutritionCoachingPage = router.asPath.includes('/nutrition_coaching')

  const dailyPriceDescription = `$${Math.round(dailyPrice)}`

  return (
    <div className={s.landingPagePlanContainer}>
      <div className={s.landingPageDesktopFsaBadgeContainer}>
        <span>FSA/HSA Eligible</span>
      </div>
      <div className={s.landingPagePlanHeaderContainer}>
        <div className={s.landingPageTitleHeader}>
          <span className={s.title}>Kickoff Program</span>
          <div className={s.fsaBadgeContainer}>
            <FsaBadge />
          </div>
        </div>
        <div className={s.landingPagePriceHeader}>
          <span className={s.landingPageDailyPrice}>
            <span>{dailyPriceDescription}</span>
            <span>/day</span>
          </span>
          <span className={s.landingPagePricingDescription}>
            {pricingDescription}
          </span>
        </div>
      </div>
      <div className={s.landingPagePlanBodyContainer}>
        <div className={s.landingPageBulletPointsSection1}>
          <BulletPoints
            productId={
              nutritionCoachingPage
                ? 'NUTRITION_LANDING_PAGE_SECTION_1'
                : 'LANDING_PAGE_SECTION_1'
            }
            className={s.ladingPageBulletPoints}
            checkStyle={s.ladingPageBulletPointsCheck}
            checkHeight={9}
            checkWidth={9}
            textStyle={s.landingPageBulletPointsSectionText}
          />
        </div>
        <div className={s.landingPageBulletPointsSection2}>
          <BulletPoints
            productId={
              nutritionCoachingPage
                ? 'NUTRITION_LANDING_PAGE_SECTION_2'
                : 'LANDING_PAGE_SECTION_2'
            }
            className={s.ladingPageBulletPoints}
            checkStyle={s.ladingPageBulletPointsCheck}
            checkHeight={9}
            checkWidth={9}
            textStyle={s.landingPageBulletPointsSectionText}
          />
        </div>
      </div>
    </div>
  )
}

export default LandingPagePlanCard

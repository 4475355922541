import cn from 'classnames'
import s from './styles.module.css'
import FsaBadge from 'components/payment/common/fsa-badge'
import BulletPoints from 'components/payment/common/bullet-points'
import { DESKTOP_WIDTH } from 'constants/breakpoints'
import { useWindowSizeCondition } from 'hooks/use-window-size-condition'
import { isServer } from 'utilities/environment'
import useIsFirstRender from 'hooks/use-is-first-render'

const LandingPagePlanCard = ({ pricingDescription, overridePrice }) => {
  const isFirstRender = useIsFirstRender()
  const isDesktop = useWindowSizeCondition(({ width }) => width > DESKTOP_WIDTH)

  if (isDesktop || isServer() || isFirstRender) {
    return (
      <div className={cn(s.desktopContainer, s.lessTopMargin)}>
        <div className={cn(s.landingPagePlanContainer, s.programCard)}>
          <div className={s.landingPageDesktopFsaBadgeContainer}>
            <span>FSA/HSA Eligible</span>
          </div>
          <div className={s.landingPagePlanHeaderContainer}>
            <div className={s.landingPageTitleHeader}>
              <span className={s.title}>Your Plan Includes</span>
              <div className={s.fsaBadgeContainer}>
                <FsaBadge />
              </div>
            </div>
          </div>
          <div className={s.landingPagePlanBodyContainer}>
            <div className={cn(s.landingPageBulletPointsSection1, s.noBorder)}>
              <BulletPoints
                productId={'LANDING_PAGE_SECTION_1'}
                className={s.ladingPageBulletPoints}
                checkStyle={s.ladingPageBulletPointsCheck}
                checkHeight={9}
                checkWidth={9}
                textStyle={s.landingPageBulletPointsSectionText}
                isPaidTrial={true}
              />
            </div>
          </div>
        </div>
        <div className={s.landingPagePlanContainer}>
          <span className={s.title}>Pricing</span>
          <div className={s.landingPagePriceHeader}>
            <span className={s.landingPageDailyPrice}>
              {overridePrice ? (
                <span>{overridePrice}</span>
              ) : (
                <>
                  <span className={s.price}>$3</span>
                  <span>/day</span>
                </>
              )}
            </span>
          </div>
          <span className={s.landingPagePricingDescription}>
            {pricingDescription}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className={s.mobileContainer}>
      <div className={cn(s.landingPagePlanContainer, s.trialPlanContainer)}>
        <div className={s.landingPagePlanHeaderContainer}>
          <div className={s.landingPageTitleHeader}>
            <span className={s.title}>Pricing</span>
            <div className={s.fsaBadgeContainer}>
              <FsaBadge />
            </div>
          </div>
          <div className={s.landingPagePriceHeader}>
            <span className={s.landingPageDailyPrice}>
              {overridePrice ? (
                <span>{overridePrice}</span>
              ) : (
                <span>$3/day</span>
              )}
            </span>
          </div>
          <span className={cn(s.landingPagePricingDescription, s.semiBlack)}>
            {pricingDescription}
          </span>
        </div>
      </div>
      <div className={cn(s.landingPagePlanContainer, s.trialPlanContainer)}>
        <div className={cn(s.title, s.trialTitle)}>Your Plan Includes</div>
        <BulletPoints
          productId={'LANDING_PAGE_SECTION_2'}
          className={s.ladingPageBulletPoints}
          checkStyle={s.ladingPageBulletPointsCheck}
          checkHeight={9}
          checkWidth={9}
          textStyle={s.landingPageBulletPointsSectionTextTrial}
          isPaidTrial={true}
        />
      </div>
    </div>
  )
}

export default LandingPagePlanCard

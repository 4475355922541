import cx from 'classnames'
import s from './styles.module.css'

const elements = {
  h1: (className, text) => <h1 className={cx(s.h1, className)}>{text}</h1>,
  h2: (className, text) => <h2 className={cx(s.h2, className)}>{text}</h2>,
  h3: (className, text) => <h3 className={cx(s.h3, className)}>{text}</h3>,
  p: (className, text) => <p className={cx(s.p, className)}>{text}</p>,
  span: (className, text) => (
    <span className={cx(s.span, className)}>{text}</span>
  ),
  label: (className, text) => (
    <label className={cx(s.label, className)}>{text}</label>
  ),
}

const Text = ({ is = 'span', className, children }) => {
  const Compoent = (elements[is] || elements.span)(className, children)
  return Compoent
}

export default Text

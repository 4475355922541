import s from './styles.module.css'

interface ICouponBadgeProps {
  coupon: any
}

export default function CouponBadge({ coupon }: ICouponBadgeProps) {
  const text = coupon.percentOff
    ? `${coupon.percentOff}% OFF`
    : `$${coupon.amountOff / 100} OFF`
  return (
    <div className={s.couponBadge}>
      <span className={s.couponText}>{text}</span>
    </div>
  )
}

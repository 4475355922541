import * as R from 'ramda'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import s from './styles.module.css'
import { AnalyticsSection } from 'containers/analytics'
import { TrackedButton } from 'components/shared/analytics'

const BILLING_PERIODS = [
  {
    text: 'Monthly',
    value: 1,
  },
  {
    text: '6 Months',
    subtext: 'Save 10%',
    value: 6,
  },
  {
    text: 'Yearly',
    subtext: 'Save 20%',
    value: 12,
  },
]

const PlanDurationSelection = ({ duration = 0, onChange }) => {
  const [selected, setSelected] = useState(duration)

  const handleClick = index => {
    // setSelected(index)
    onChange(BILLING_PERIODS[index].value)
  }

  useEffect(() => {
    setSelected(BILLING_PERIODS.findIndex(({ value }) => value === duration))
  }, [duration])

  return (
    <AnalyticsSection name="Plan Duration Selection">
      <div className={s.container}>
        <div className={s.options}>
          {R.addIndex(R.map)((period, index) => {
            return (
              <TrackedButton
                key={`plan-${index}`}
                name={period.text}
                className={cx(s.option, index === selected ? s.selected : null)}
                onClick={() => handleClick(index)}>
                <div className={s.text}>{period.text}</div>
                <div className={s.subtext}>{period.subtext}</div>
              </TrackedButton>
            )
          }, BILLING_PERIODS)}
        </div>
      </div>
    </AnalyticsSection>
  )
}

export default PlanDurationSelection

import s from './styles.module.css'

interface IProductHeaderProps {
  name: string
  billingPeriodInMonths: number
  monthlyPriceWithoutDiscount: number
  monthlyPrice: number
  coupon: any
  isFreeTrial: boolean
  trialDays?: number
}

export default function ProductHeader({
  name,
  billingPeriodInMonths,
  monthlyPriceWithoutDiscount,
  monthlyPrice,
  coupon,
  isFreeTrial,
  trialDays,
}: IProductHeaderProps) {
  return (
    <div className={s.programBaseProductHeader}>
      <h4 className={s.programBaseProductTitle}>{name}</h4>
      <div>
        {(billingPeriodInMonths !== 1 || !!coupon || isFreeTrial) && (
          <span className={s.programBaseProductStrikeThrough}>
            ${monthlyPriceWithoutDiscount}
          </span>
        )}
        <span className={s.programBaseProductSubTitle}>
          ${monthlyPrice}
          {isFreeTrial ? '' : '/mo'}
        </span>
      </div>
    </div>
  )
}
